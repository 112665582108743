import React from "react";
import Navbar from "./component/Navbar/navbar"
import Intro from "./component/Intro/intro"
import About from "./component/about/about"
import Contact from "./component/contact/contact";
import Footer from "./component/footer/footer"
import Clinic from "./component/clinic/clinic"
import Videos from "./component/videos/videos"



function App() {
  const [arabic, setArabic] = React.useState(false)

  function handleArabic(){
    setArabic(prevArabic => !prevArabic)
  }

  return (
    <div className="App">
      <Navbar arabic={arabic} handleArabic={handleArabic}/>
      <Intro arabic={arabic}/>
      <About arabic={arabic}/>
      <Clinic arabic={arabic}/>
      <Videos arabic={arabic}/>
      <Contact arabic={arabic}/>
      <Footer/> 
    </div>
  );
}

export default App;
