import React from "react";
import './intro.css';
import bg from '../../assets/bg.png';
import { Link } from "react-scroll";

const Intro = (props) =>{

    return( 
        <section id="intro">
            <div className={props.arabic ? "arabicIntroContent" : "introContent"}>
                <span className="hello">{props.arabic ? ",مرحبا" : "Hello,"}</span>
                <span className="introText">{props.arabic ? "انا" : "I'm "}<span className="introName">{props.arabic ? " شريف" : "sherif"}</span> <br/>{props.arabic ? "دكتور-جراح" : "Doctor-surgeon"}</span>
                <p className="introPara"><span className={props.arabic ? "arabicSurgeon" : ""}>{props.arabic ? "انا جراح" : "I am a surgeon "}</span> <br/> {props.arabic ?  " و استاذ مساعد بحامعة بني سويف" : "and an  assistant professor at bani swif university"}</p>
                <Link to="about" spy={true} smooth={true} offset={-50} duration={500} ><button className="btn">{props.arabic ? "المزيد عني " : "More About Me"}</button></Link>
            </div>
            <img src={bg}  alt="img" className="bg"/>

        </section>
    ) 
}

export default Intro;