import React from "react";
import './clinic.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { faHospitalUser } from "@fortawesome/free-solid-svg-icons";



const Clinic = (props)=>{
    
    return(
        <section id="clinic">
            <span className="clinicTitle">{props.arabic && <FontAwesomeIcon icon={faHospital}/>} {props.arabic ? "عيادتي" : "My Clinic"} {!props.arabic && <FontAwesomeIcon icon={faHospital}/>}</span>
            <span className="clinicDesc">
                {props.arabic ? " نحن هنا لنقدم لك احسن تجربة طبية , يمكنك الاطلاع على معلومات العيادة" :
"We are here to provide you the best medical experience; You can take a look at the clinic's information"}
              </span>
            <div className="clinicBars">


                <div className="clinicBar">
                    <div className="clinicBartText">
                        <h2 className={props.arabic ? "arabic--title" : ""}>{props.arabic ? "الخدمات" : "Services"} <FontAwesomeIcon icon={faHospitalUser}/></h2>
                        <p className={props.arabic ? "arabic--desc" : ""}>{props.arabic ? "كشف طبي، تشخيص المرض، كتابة العلاج و الرشتة الطبية" : "Examination, Diagnosis of a disease, Writing a treatment "}</p>
                    </div>
                </div>

                <div className="clinicBar">
                    <div className="clinicBartText">
                        <h2 className={props.arabic ? "arabic--title" : ""}>{props.arabic ? "المواعيد" : "Appointments"} <FontAwesomeIcon icon={faCalendarCheck}/></h2>
                        <p className={props.arabic ? "arabic--desc" : ""}><span className="clinicBarStyle">|</span> {props.arabic ? ":الجمعة:مقفول" : "Friday:closed"} <span className="clinicBarStyle">|</span> {props.arabic ? "السبت:٤-٦ م" :"Saturday:4-6pm"}
                        <span className="clinicBarStyle">|</span> {props.arabic ? "الاحد:٤-٦ م"  :"Sunday:4-6pm"} <span className="clinicBarStyle">|</span>
                         {props.arabic ? " الاثنين:٤-٦ م" : "Monday:4-6"}<span className="clinicBarStyle">|</span>{props.arabic ? " الثلاثاء:٤-٦ م"  : "Tuesday:4-6pm"}<span className="clinicBarStyle">|</span>
                         {props.arabic ? "الاربعاء:٤-٦ م" : "wednesday:4-6pm"}<span className="clinicBarStyle">|</span>{props.arabic ? "الخميس:مقفول" : "thursday:closed"}<span className="clinicBarStyle">|</span>
                        </p>
                    </div>
                </div>

                <div className="clinicBar">
                    <div className="clinicBartText">
                        <h2 className={props.arabic ? "arabic--title" : ""}>{props.arabic ? "اللوكيشن" : "Location"} <FontAwesomeIcon icon={faLocationDot}/></h2>
                        <p className={props.arabic ? "arabic--desc" : ""}>
{props.arabic ? "  امام معهد الاورام, ٥ش, القصر العيني" : "5 Al-Qasr Al-Ainy Street - in front of the Oncology Institute"} <a href="https://maps.app.goo.gl/ow87e1Qwc9HCb6T76" target="_blank" rel="noopener noreferrer" className="loacationLink">click</a> </p>
                    </div>
                </div>

                <div className="clinicBar">
                    <div className="clinicBartText">
                        <h2 className={props.arabic ? "arabic--title" : ""}>{props.arabic ? "تواصل معنا" : "Contact us"} <FontAwesomeIcon icon={faPhone}/></h2>
                        <p className={props.arabic ? "arabic--desc" : ""}>{props.arabic ? "يمكنك الضغط على هذا الزرار للتواصل بالعيادة" : "you can click this button to contact the clinic"} <a href="tel:201098425644" class="call-btn">{props.arabic ? "اتصل" : "Call"}</a> </p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Clinic