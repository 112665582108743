import React, { useState } from "react";
import './navbar.css';
import logo from '../../assets/logo.png';
import message from '../../assets/message.png'
import {Link } from 'react-scroll';
import menu from '../../assets/menu.png';

 

const Navbar = (props) =>{ 

    const[showMenu, setShowMenu] = useState(false)
    return(
        <nav className={props.arabic ? "arabic" : ""}>
            <img src={logo} alt="Logo" className="logo"/>
            <div className="desktopMenu">
                <Link activeClass="active" to="intro" spy={true} smooth={true} offset={-100} duration={500}  className="desktopMenuListItem">{props.arabic ? "الصفحة الرئيسية" : "Home"}</Link>
                <Link activeClass="active" to="about" spy={true} smooth={true} offset={-50} duration={500}  className="desktopMenuListItem">{props.arabic ? "معلومات عني " : "About"}</Link>
                <Link activeClass="active" to="clinic" spy={true} smooth={true} offset={-60} duration={500}  className="desktopMenuListItem" >{props.arabic ? "العيادة" : "Clinic"}</Link>
                <Link activeClass="active" to="videos-container" spy={true} smooth={true} offset={-60} duration={500}  className="desktopMenuListItem">{props.arabic ? "الفديوهات" : "Videos"}</Link>
                <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={500}  className="desktopMenuListItem">{props.arabic ? " تواصل معي" : "Contact me"}</Link>

            </div>




                <div className="toggler">
                <p className="toggler--english">{props.arabic ? "الانجليزية" : "English"}</p>
                <div className="toggler--slider" onClick={props.handleArabic}>
                    <div className="toggler--slider--circle"></div>
                </div>
                <p className="toggler--arabic">{props.arabic ? "العربية" : "Arabic"}</p>
                 </div>
 


                <img src={menu} alt="Menu" className="mobMenu" onClick={()=>setShowMenu(!showMenu)}/>
            <div className="navMenu" style={{display: showMenu? 'flex':'none'}}>
                <Link activeClass="active" to="intro" spy={true} smooth={true} offset={-100} duration={500}  className="listItem" onClick={()=>setShowMenu(false)}>{props.arabic ? "الصفحة الرئيسية" : "Home"}</Link>
                <Link activeClass="active" to="about" spy={true} smooth={true} offset={-50} duration={500}  className="listItem" onClick={()=>setShowMenu(false)}>{props.arabic ? "معلومات عني " : "About"}</Link>
                <Link  activeClass="active" to="clinic" spy={true} smooth={true} offset={-65} duration={500}  className="listItem" onClick={()=>setShowMenu(false)}>{props.arabic ? "العيادة" : "Clinic"}</Link>
                <Link  activeClass="active" to="videos-container" spy={true} smooth={true} offset={-50} duration={500}  className="listItem" onClick={()=>setShowMenu(false)}>{props.arabic ? "الفديوهات" : "Videos"}</Link>
                <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-50} duration={500}  className="listItem" onClick={()=>setShowMenu(false)}>{props.arabic ? " تواصل معي" : "Contact me"}</Link>

            </div>
        </nav>
    )
}

export default Navbar;




    

// <button className="desktopMenuBtn" onClick={()=>{
// document.getElementById('contact').scrollIntoView({behavior:'smooth'});
// }}>
// <img src={message} className="desktopMenuImg" />Contact Me</button>
