import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

import "./videos.css";
const Videos = (props) => {
    return (
      <div id="videos-container">
        <span  className="videos-title">{props.arabic &&<FontAwesomeIcon icon={faVideo}/>} {props.arabic ? "الفديوهات" : "Videos"} {!props.arabic &&<FontAwesomeIcon icon={faVideo}/>}</span>
      <section id="videos-section">
        <div className="video">
        <iframe className="iframe"  width="560" height="315" src="https://www.youtube.com/embed/Zhelw7HtxHU?si=kW6zAzmxtggKRMzp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <span className="video-text">{
          props.arabic ? "المزيد عني و عن عيادتي" :
          "More about me and my clinic"
          }</span>
        </div>

        <div className="video">
        <iframe className="iframe" width="560" height="315" src="https://www.youtube.com/embed/GHnd3M15X3M?si=Cq2Q1aonOyNok9oj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <span className="video-text">{
        props.arabic ? "احذر , علامات شائعة من اعراض اورام الرجال" :
        "Beware, common signs and symptoms of men's tumors"}</span>
        </div>

          <div className="video">
          <iframe className="iframe" width="560" height="315" src="https://www.youtube.com/embed/8dxqeV9ayzQ?si=K5QZwitw2KqgI-1E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <span className="video-text">{
          props.arabic ? "احذري , علامات شائعة من اعراض اورام الثدي" :
        "Beware, common signs and symptoms of breast tumors"}</span>
        </div>
      </section>
      </div>

    );
  };

export default Videos;