import React from "react";
import './about.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserDoctor,faAward,faStethoscope,faCrown } from "@fortawesome/free-solid-svg-icons";
const PDF_FILE_URL = process.env.PUBLIC_URL + "/cv.pdf";







const About = (props) =>{

    const handleDownloadCV = () => {
        // Open the PDF file in a new tab
        window.open(PDF_FILE_URL, "_blank");
      };
    return(
        <section id="about">
            <span className="aboutTitle">{props.arabic && <FontAwesomeIcon icon={faUserDoctor}/>}{props.arabic ? " معلومات عني" : "About me"} {!props.arabic && <FontAwesomeIcon icon={faUserDoctor}/>}</span>
            <span className="aboutDesc">{
                props.arabic ? "  السلام عليكم انا شريف البرنس عمري 50 سنة علشان تعرف عني اكتر يمكنك متابعة القراءة" : 
                "Hi there my name is Sherif el Prince;i am 50 years old;to learn more about me, you can continue reading"
                }
              </span>

              <div className="aboutBars">

                <div className="aboutBar">
                    <div className="aboutBartText">
                        <h2 className={props.arabic ? "arabic--title" : ""}>{props.arabic ? "الخبرة" :"Experience"} <FontAwesomeIcon icon={faCrown}/> </h2>
                        <p className={props.arabic ? "arabic--desc" : ""}>{props.arabic ?   "٢٣ سنة من الخبرة في جراحة الاورام و عمليات الجراحة العامة" : "23 years Experience in Surgical oncology and general surgey operations"}<button className="downloadCvBtn" onClick={()=>{handleDownloadCV(PDF_FILE_URL)}}>
                Download CV
        </button> </p>
                    </div>
                </div>

                <div className="aboutBar">
                    <div className="aboutBartText">
                        <h2 className={props.arabic ? "arabic--title" : ""}>{props.arabic ? "الشهادات" : "Certificates"} <FontAwesomeIcon icon={faAward}/></h2>
                        <p className={props.arabic ? "arabic--desc" : ""}>MD<span className="aboutBarStyle">......</span>MRCS</p>
                    </div>
                </div>

                <div className="aboutBar">
                    <div className="aboutBartText">
                        <h2 className={props.arabic ? "arabic--title" : ""}>{props.arabic ? "العمليات" : "Surgeries"} <FontAwesomeIcon icon={faStethoscope}/></h2>
                        <p className={props.arabic ? "arabic--desc" : ""}>{props.arabic ? "لقد قمت بأجراء اكثر من ٣٥ نوع من عمليات الاورام و عمليات الجراحة العامة" : 
"i have made more than 35 types of operations: Oncology operations: and General surgery operations"
                            }</p>
                    </div>
                </div>

                <div className="aboutBar">
                    <div className="aboutBartText">
                        <h2 className={props.arabic ? "arabic--title" : ""}>{props.arabic ? "الوظيفة الحالية" : "Current positon"} <FontAwesomeIcon icon={faUserDoctor}/> </h2>
                        <p className={props.arabic ? "arabic--desc" : ""}>
                            {props.arabic ? "استشاري الجراحة العامة و جراحة الأورام و أستاذ مساعد جراحة الأورام" : 
"consultant general surgey and Surgical oncology and an Assitant professor surgical oncology"}
                        </p>
                    </div>
                </div>



              </div>
        </section>
    )
}

export default About;

